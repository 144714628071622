@use "../variables/variables" as vars;

.error-page:has(.error-title) {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
}

.homepage {
  .header {
    z-index: 2;
    left: 15px;
    width: calc(100% - 30px);
    position: absolute;

    input {
      background-color: transparent;
      color: #fff;

      &::placeholder {
        color: #fff;
        opacity: 1;
      }

      &::-ms-input-placeholder {
        color: #fff;
      }
    }

    svg {
      path {
        fill: #fff;
      }
    }

    .header-burger {
      span {
        background-color: #fff;
      }
    }
  }

  .navlink {
    color: #fff;

    &.active {
      color: #fff;

      &::before {
        background-color: #fff;
      }
    }

    &:hover {
      color: #fff;

      &::before {
        background-color: #fff;
      }
    }

    @media only screen and (max-width: vars.$small) {
      &.active {
        &::before {
          background-color: vars.$primary-light-color;
        }
      }

      &:hover {
        color: vars.$primary-color;

        &::before {
          background-color: vars.$primary-color;
        }
      }
    }
  }
}
