@font-face {
  font-family: "Raleway-Regular";
  src: url("../fonts/Raleway-Regular.woff2") format("woff2"),
    url("../fonts/Raleway-Regular.ttf") format("truetype"),
    url("../fonts/Raleway-Regular.eot");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: auto;
}

@font-face {
  font-family: "Raleway-Bold";
  src: url("../fonts/Raleway-Bold.woff2") format("woff2"),
    url("../fonts/Raleway-Bold.ttf") format("truetype"),
    url("../fonts/Raleway-Bold.eot");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: auto;
}

@font-face {
  font-family: "Raleway-Medium";
  src: url("../fonts/Raleway-Medium.woff2") format("woff2"),
    url("../fonts/Raleway-Medium.ttf") format("truetype"),
    url("../fonts/Raleway-Medium.eot");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: auto;
}

@font-face {
  font-family: "Raleway-Light";
  src: url("../fonts/Raleway-Light.woff2") format("woff2"),
    url("../fonts/Raleway-Light.ttf") format("truetype"),
    url("../fonts/Raleway-Light.eot");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: auto;
}

@font-face {
  font-family: "Raleway-ExtraLight";
  src: url("../fonts/Raleway-Extra-Light.ttf") format("truetype"),
    url("../fonts/Raleway-Extra-Light.woff2") format("woff2"),
    url("../fonts/Raleway-Extra-Light.eot");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: auto;
}

@font-face {
  font-family: "Raleway-Thin";
  src: url("../fonts/Raleway-Thin.woff2") format("woff2"),
    url("../fonts/Raleway-Thin.ttf") format("truetype"),
    url("../fonts/Raleway-Thin.eot");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: auto;
}
